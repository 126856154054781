import React from "react"
import Texte from "../Texte"

const Textes = ({ input }) => {
	// console.log(input)
	const isSingle = input.textes.length === 1
	// console.log(input.textes)
	return (
		<section className="module module-texte">
			<div className="container">
				<div className="row">
					<div className="col-md-2 hidden-xs"></div>
					{isSingle &&
					input.textes.map(({ text }, i) => (
						<div key={i} className={"col-md-8 col-xs-12"}>
							<Texte texte={text} />
						</div>
					))
					}
					{!isSingle &&
					input.textes.map(({ text }, i) => (
						<div key={i} className={i === 0 ? "col-md-4" : "col-md-5"}>
							<Texte texte={text} />
						</div>
					))}
					
					<div className="col-md-1"></div>
				</div>
			</div>
		</section>
	)
}

export default Textes
