import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
const {
  api: { projectId, dataset },
} = require("../../sanity.json")

export default ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: 1024 },
    projectId,
    dataset
  )
  // console.log(node)
  // console.log(projectId)
  // console.log(dataset)
  // console.log(fluidProps)
  return (
    <figure>
      <Img fluid={fluidProps} alt={node.alt} />
      {/* <figcaption>{node.caption}</figcaption> */}
    </figure>
  )
}
